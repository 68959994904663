import React , { useState } from 'react';
import './App.css';
import Feed from './components/Feed/Feed';
import MyIdeas from './components/Feed/MyIdeas';
import MyNewsFeed from './components/Feed/MyNewsFeed';
import Header from './components/Header/Header';
import HeaderUserVisite from './components/Header/HeaderUserVisite';
import Login from './components/Login/Login';
import Sidebar from './components/Sidebar/Sidebar';
import Widgets from './components/Widgets/Widgets';
import { useStateValue } from './StateProvider';
import  {useAuth } from './contexts/AuthContext'

import Signup from "./components/Login/Signup";
import { AuthProvider } from './contexts/AuthContext'
import PrivateRoute from './components/Login/PrivateRoute';



import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ForgotPassword from "./components/Login/ForgotPassword"
/*import LoginHeader from './LoginHeader.js';
import Login from './Login';
import RegisterHeader from './RegisterHeader.js';
import Register from './Register.js';
import './App.css';
import { auth } from './firebase.js';
import HomeHeader from './HomeHeader.js';
import Sidebar from './Sidebar.js';
import Sidebar2 from './Sidebar2.js';
import Posts from './Posts.js';


import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

//import Login from "./Login"






function App() {

  const [user, setUser] = useState([]);

  auth.onAuthStateChanged((authUser) => {
    if (authUser) {
      setUser(authUser)
    } else {
      setUser(false);
    }
  })
  return (
    <div className="app">
      <Router>
        <Switch>
          <Route path="/login">
            <LoginHeader />
            <Login />
          </Route>
          <Route path="/register">
            <RegisterHeader />
            <Register />
          </Route>
          <Route path="/">
            <HomeHeader user={user} />
            <div className="app__page">
              <Sidebar user={user} />
              <div className="app__posts">
                <Posts user={user} />
              </div>
              <Sidebar2 />

            </div>
          </Route>
        </Switch>
      </Router>
    </div>
  )
}

export default App
*/

//B end

function App() {
 

 

 const [{user, currentUser }, dispatch] = useStateValue();
 // const { user } = useAuth();
  return (
    <div className="app">
      <AuthProvider>
 <Router>
   <div>
   
    
     <Route path="/forgot-password" component={ForgotPassword} />
     <Route exact path="/signup" component={Signup} />
   </div>
 </Router>
</AuthProvider>
      {!localStorage.getItem("user") ? (
        
        <Login />
      ) : ( 
        <>
        {localStorage.getItem("clickselect1")==1 ? (
          <HeaderUserVisite />
          
          
          
          ) : ( 
            <Header />
          )}
          <div className="app__body">
            <Sidebar />
           
            {localStorage.getItem("clickselect")==1 ? (
        
        <Feed />
      ) : ( 
       <>
        {localStorage.getItem("clickselect")==2 ? (
        
          <MyIdeas />
        ) : ( 
         
          
          <MyNewsFeed />
          
        )}
        </>
      
        
      )}


            <Widgets />
          </div>
        </>
      )}





    </div>
  );
}

export default App;

