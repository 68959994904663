import React from 'react';
import SidebarRow from './SidebarRow/SidebarRow';
import './Sidebar.css';
import { ExpandMoreOutlined } from '@material-ui/icons';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags';
import PeopleIcon from '@material-ui/icons/People';
import ChatIcon from '@material-ui/icons/Chat';
import StorefrontIcon from '@material-ui/icons/Storefront';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import { useStateValue } from '../../StateProvider';
import { IconButton, colors } from '@material-ui/core';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';

function Sidebar() {
  const [{user} , dispatch] = useStateValue();

  return (
    <div className="sidebar">
      <SidebarRow
       src={""}
       title={""}
      />
     <IconButton  onClick={() => window.open('https://news.google.com/covid19/map?hl=en-IN&mid=%2Fm%2F055vr&gl=IN&ceid=IN%3Aen',"_blank")}>
      <SidebarRow 
       
       Icon={LocalHospitalIcon}
        title=" COVID-19 Information Center"
        
      />
      </IconButton>
     
      <IconButton  onClick={() => window.open(' https://theideasplanet.com/',"_blank")}>
      <SidebarRow Icon={EmojiFlagsIcon} title="Pages" /></IconButton>
      <IconButton  onClick={() => window.open('https://theideasplanet.com/idea-landing-page.html',"_blank")}>
      <SidebarRow Icon={WbIncandescentIcon} title="Idea's Planet" /></IconButton>
      <IconButton  onClick={() => window.open('https://theideasplanet.com/News-landing-page.html',"_blank")}>
      <SidebarRow Icon={ChatIcon} title="News Planet" /></IconButton>
      <IconButton  onClick={() => window.open('https://theideasplanet.com/about-us.html',"_blank")}>
      <SidebarRow Icon={StorefrontIcon} title=" About Us" /></IconButton>
      
    </div>
  );
}

export default Sidebar;
