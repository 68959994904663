import { Avatar } from '@material-ui/core';
import React, { useState } from 'react';
import './MessageSender.css';
import VideocamIcon from '@material-ui/icons/Videocam';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import { useStateValue } from '../../../StateProvider';
import db,{storage} from './../../../firebase'
import firebase from 'firebase'
import { PermDataSettingSharp, Timelapse } from '@material-ui/icons';
//import storage from  './../../../firebase'

function MessageSender() {
  const [{user} , dispatch] = useStateValue();
  const [input, setInput] = useState('');
  const [inputURL, setInputURL] = useState('');
  const [image , setImage] = useState('');
  const displayName = localStorage.getItem("displayName");
  
  const photoURL = localStorage.getItem("photoURL");
  const idU = localStorage.getItem("email");
  const uuidU =localStorage.getItem('user_UID')

 var ref= firebase.firestore.DocumentReference ;
 var docid;
  const handleSubmit =   (e) => {
    e.preventDefault();

    var uriname;
    

    if(image == null)
      return;
      storage.ref(`/images/${image.name + Math.random()}`)
    .put(image)
    .then(snapshot => {
      return snapshot.ref.getDownloadURL();
    })
    .then(url  =>{
      uriname = url

     
      
      const res =  db.collection('posts').add({
       
       
        message: input,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        
        profilePic:photoURL,
        username: displayName,
        image: uriname,
        idU:idU,
        uuidU:uuidU
      })
      .then(function(docRef) {
        console.log("Tutorial created with ID: ", docRef.id);
        docid=docRef.id;
        db.collection("posts").doc(docid).update({key: docid});
    })








    
      setInput('');
      setInputURL('');
      setImage('');
    
    })
    .catch(error => {
      alert(error.message);
      setImage('');
    });


    var name;
    if(image == null && uriname == null  )
   {
  //storage.ref(`/images/${image.name}`).put(image)
  //.on("state_changed" , alert("success") , alert);

  //inputURL = storage.getDownloadURL(); //storage.name;

  const res =   db.collection('posts').add({
    message: input,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    profilePic:photoURL,
    username: displayName,
    idU:idU
  })
  .then(function(docRef) {
    console.log("Tutorial created with ID: ", docRef.id);
    docid=docRef.id;
    db.collection("posts").doc(docid).update({key: docid});
})

  setInput('');
  setInputURL('');
  setImage('');


  
  
   }
  

   
   
   
  };

  



  const upload = (e) => {
    var testttt;
    e.preventDefault();
{
    if(image == null)
      return;
    storage.ref(`/images/${image.name + Math.random()}`)
    .put(image)
    .then(snapshot => {
      return snapshot.ref.getDownloadURL();
    })
    .then(url => {
      testttt = url
        
    })
    .catch(error => {
      alert(error.message);
    });
  

   
  
   
}
  };

  return (
    <div className="messageSender">
      <div className="messageSender__top">
      <Avatar src={photoURL}/>
        <form>
          <input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            type="text"
            className="messageSender__input"
            placeholder={`What's on your mind, ${displayName}?`}
          />
          <input
            value={inputURL}
            onChange={(e) => setInputURL(e.target.value)}
            type="text"
            placeholder="Image URL (Optional)"
          />
          <button onClick={handleSubmit} type="submit">
            Hidden Button
          </button>
        </form>
      </div>
      <div className="messageSender__bottom">
        <div className="messageSender__option">
          <VideocamIcon style={{ color: 'red' }} />
          <h3>Live Video</h3>
        </div>
        <div className="messageSender__option">
          <PhotoLibraryIcon style={{ color: 'green' }} />
          <h3>Photo/Video</h3>
          <input type="file" onChange={(e)=>{setImage(e.target.files[0])}}/>
       
          
         
        </div>
        <div className="messageSender__option">
          <InsertEmoticonIcon style={{ color: 'orange' }} />
          <h3>Feeling/Activity</h3>
        </div>
      </div>
    </div>
  );
}

export default MessageSender;
