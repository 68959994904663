import { Avatar } from '@material-ui/core';
import React, { useEffect, useState }from 'react';
import Select from 'react-select';
import './Post.css';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import NearMeIcon from '@material-ui/icons/NearMe';
import { ExpandMoreOutlined } from '@material-ui/icons';
import db from '../../../firebase';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ReportIcon from '@material-ui/icons/Report';
import Demo from './dd';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';

import firebase from 'firebase'



     
function Post({ keyy,profilePic, image, username, timestamp, message ,userId,noLikes,uuid}) {
//B start
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState('');
  const [show, setShow] = useState('like2');
  const [show2, setShow2] = useState('textforlike');
  const [flag, setFlag] = React.useState(true);
  const [thumb, setThumbUp] = useState(false);

  const ThumbUpButton = {
    backgroundColor: 'rgb(115, 250, 179)',
    border: 'none',
    borderRadius: '5px',
  }
  
  const ThumbStyle = {
    backgroundColor: 'red',
    border: 'none',
    padding: '5px',
    borderRadius: '5px',
    margin: '1rem'
  }


  useEffect(() => {
    let unsubscribe;
    if (keyy) {
        unsubscribe = db.collection("posts").doc(keyy).collection("comments").orderBy("timestamp", "desc").onSnapshot((snapshot) => {
            setComments(snapshot.docs.map((doc) => doc.data()));
        });
    }
    return () => {
       // unsubscribe();
    }
}, [keyy]);

useEffect(() => {
    db.collection("posts")
        .doc(keyy)
        .collection("likes")
        .doc(userId)
        .get()
        .then(doc2 => {
            if (doc2.data()) {
                if (show == 'like2') {
                    setShow('like2 blue');
                    setShow2('textforlike bluetextforlike')
                    setFlag(!flag);
                } else {
                    setShow('like2');
                    setShow2('textforlike')
                    setFlag(flag);
                }
            }
        })
}, [keyy, userId]);

const visitehandle  = (uuid) => {
 
  localStorage.setItem('visitUser',uuid)
  localStorage.setItem('clickselect1',1)
  window.location.reload();
  return
  }

const likeHandle = (event) => {
    event.preventDefault();
    if (show == 'like2') {
        setShow('like2 blue');
        setShow2('textforlike bluetextforlike')
        setFlag(!flag);
    } else {
        setShow('like2');
        setShow2('textforlike')
        setFlag(flag);
    }

    db.collection('posts')
        .doc(keyy)
        .get()
        .then(docc => {
            const data = docc.data()
            console.log(show)
            if (show == 'like2') {
                db.collection("posts")
                    .doc(keyy)
                    .collection("likes")
                    .doc(userId)
                    .get()
                    .then(doc2 => {
                        if (doc2.data()) {
                            console.log(doc2.data())
                        } else {
                            db.collection("posts").doc(keyy).collection("likes").doc(userId).set({
                                likes: 1
                            });
                            db.collection('posts').doc(keyy).update({
                                noLikes: data.noLikes + 1
                            });
                        }
                    })

            } else {
               
              if(data.noLikes>=1)
              {
                  db.collection('posts').doc(keyy).collection('likes').doc(userId).delete().then(function () {
                    db.collection('posts').doc(keyy).update({                      
                        noLikes: data.noLikes - 1                      
                      
                    });
                })
              }
              else
              {
                db.collection('posts').doc(keyy).collection('likes').doc(userId).delete().then(function () {
                  db.collection('posts').doc(keyy).update({                      
                      noLikes:0                     
                    
                  });
              })
              }
              
            }
        })

}


const postComment = (event) => {
 
    event.preventDefault();

 
    db.collection("posts").doc(keyy).collection("comments").add({
        text: comment,
        username: username,        
        profilepicC:profilePic,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });
    setComment('')
    
}

//B end
  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  
  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDel = (keyy) => {
    db.collection('posts')    
    .doc(keyy).delete().then(() => {
        console.log("Document successfully deleted!");
    }).catch((error) => {
        console.error("Error removing document: ", error);
    });


  }
 

  return (
    <div className="post">
      <div className="post__top" onClick={() => visitehandle(uuid)}>
        <Avatar src={profilePic} className="post__avatar" />
        <div className="post__topInfo">
          <h3>{username}</h3>
          <p>{new Date(timestamp?.toDate()).toUTCString()}</p>
        </div>
      </div>
      <div className="post__bottom">
        <p>{message}</p>
      </div>
      <div className="post__image">
        <img src={image} alt="" />
      </div>
      <div className="post__likeandlove">
                <i className="post__like" >
        
                  </i>
                <i className="post__heart" >
                <ThumbUpIcon color="primary"  />   
                  </i>
                <p>{noLikes} Likes</p>
            </div>
      
      <div className="post__options">
      <div className="post__option" onClick={likeHandle}>
         
          <button
  style={{
    border: 'none',
    backgroundColor: 'transparent'
  }}
  onClick={() => setThumbUp(thumb => !thumb)}
>
  <ThumbUpIcon color={thumb ?  "primary" :"secondary"} /><p>Likes</p>
</button>
        </div>
        <div className="post__option">
          <ChatBubbleOutlineIcon />
          <p>Comment</p>
        </div>
        
        <div className="post__option">
          <NearMeIcon />
          <p>Share</p>
        </div>
        <div className="post__option">
      
        <div>
       
      <Button 
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color= "#81d4fa"
        onClick={handleClick}
      >
      < ArrowDropDownOutlinedIcon/>
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <ListItemIcon >
          
           <div class="font-icon-wrapper" onClick={() => handleDel(keyy)}>
        
             
          <DeleteForeverIcon fontSize="small"    />
  </div>
            
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <ReportIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Report" />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <InboxIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Inbox" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
     
  
        </div>
      </div>
      <form onSubmit={postComment}>
                <div className="commentBox">
                    <Avatar
                        className="post__avatar2"
                        alt=""
                        src={profilePic} 
                    />
                    <input className="commentInputBox" type="text" placeholder="Write a comment ... "  value={comment}   onChange={(e) => setComment(e.target.value)}/>
                    <input type="submit" disabled={!comment} className="transparent__submit" />
                </div>
                <p className="pressEnterToPost">Press Enter to post</p>
             
            </form>

            {
                comments.map((comment) => (
                    <div className={`comments__show myself`}>
                        <Avatar
                            className="post__avatar2"
                            alt=""
                            src={comment.profilepicC}
                        />
                        <div class="container__comments">
                            <p><span>{comment.username}</span><i class="post__verified"></i>&nbsp; {comment.text}</p>
                        </div>
                    </div>
                ))
            }
    </div>
  );
}

export default Post;
