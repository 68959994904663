import React, { useEffect, useState } from 'react';
import Story from './Story/Story';
import './StoryReel.css';


import db from './../../../firebase'

var img =
  'https://tip.theideasplanet.com/images/tip.png';

function StoryReel() {

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    db.collection('posts').orderBy('timestamp', 'desc').limit(5).onSnapshot(snapshot => {
      setPosts(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data()})))
    })
  }, [])


  return (
    <div className="storyReel">

    

     {posts.map(post => (
//img=post.data.image

(post.data.image!='') ? (
        
  <Story
  image={post.data.image}
  profileSrc={post.data.profilePic}
  title={post.data.message}
   
  />
) :(

        <Story
     image={img}
     profileSrc={post.data.profilePic}
     title={post.data.message}
      
     />
)
     ))}
    </div>
  );
}

export default StoryReel;
