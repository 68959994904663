import React, { useEffect, useState } from 'react';
import './Feed.css';
import MessageSender from './MessageSender/MessageSender';
import Post from './Post/Post';
import StoryReel from './StoryReel/StoryReel';
import db from './../../firebase'


function Feed() {
 // const history = useHistory("");  //B
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    
    db.collection('posts').orderBy('timestamp', 'desc').onSnapshot(snapshot => {
      setPosts(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data()})))
    })
  }, [])



  return (
    <div className="feed">
      <StoryReel />
      <MessageSender />
      {posts.map(post => (
       
      <Post
        keyy={post.data.key}
        profilePic={post.data.profilePic}
        message={post.data.message}
        timestamp={post.data.timestamp}
        username={post.data.username}       
        image={post.data.image}
        userId={post.data.idU}
        noLikes={post.data.noLikes}
        uuidU={post.data.uuidU}
      />
      ))}
    </div>

    
  );
}

export default Feed;
