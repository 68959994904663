/*
import { Avatar } from '@material-ui/core';
import React, { useEffect, useState }from 'react';
import Select from 'react-select';
import './Followers.css';

import db from '../../firebase';


import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import firebase from 'firebase'



     
function Followers({ profilePic, username, uuid}) {
//B start
  
  





const visitehandle  = (uuid) => {
 
  localStorage.setItem('visitUser',uuid)
  localStorage.setItem('clickselect1',1)
  window.location.reload();
  return
  }


//B end
  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  
  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  
 

  return (
    <div className="post">
      <div className="post__top" onClick={() => visitehandle(uuid)}>
        <Avatar src={profilePic} className="post__avatar" />
        <div className="post__topInfo">
          <h3>{username}</h3>
          
        </div>
      </div>
      <div className="post__bottom">
        <Button 
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color= "#81d4fa"
        onClick={() => visitehandle(uuid)}
      />
      </div>
          </div>
  );
}

export default Followers;
*/


import { Avatar } from '@material-ui/core';
import React from 'react';
import './Followers.css';

function Followers({ profilePic, username, uuid }) {
  return (
    <div style={{ backgroundImage: `url(${profilePic})` }} className="Followers">
      <Avatar className="Followers__avatar" src={profilePic} />
      <h4>{username}</h4>
    </div>
  );
}

export default Followers;
