import React from 'react';
import { Avatar } from '@material-ui/core';

import './SidebarRow.css';

function SidebarRow({ src, Icon, title }) {
  return (
    <div className="sidebarRow">
      {src && <Avatar/>}
      {Icon && <Icon />}
      <h6>{title}</h6>
    </div>
  );
}

export default SidebarRow;
