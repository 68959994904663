// For Firebase JS SDK v7.20.0 and later, measurementId is optional

import firebase, { firestore } from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyDpUO3Yf-3Qo8IffqAke5X5IsiILg1TtwQ",
    authDomain: "theideasplanet-16b29.firebaseapp.com",
    projectId: "theideasplanet-16b29",
    storageBucket: "theideasplanet-16b29.appspot.com",
    messagingSenderId: "268238978607",
    appId: "1:268238978607:web:300fd0307b010ad225d0da",
    measurementId: "G-V4Y168PRBP"
  };


  const firebaseApp = firebase.initializeApp(firebaseConfig);
  firebase.firestore().settings({ experimentalForceLongPolling: true }); //add this..
  const db = firebase.firestore();
  const auth = firebase.auth();
  const provider = new firebase.auth.GoogleAuthProvider();
  const eprovider = new firebase.auth.EmailAuthProvider();

  var storage = firebase.storage();

  
  export {firebaseApp};
  export { auth,db, provider ,eprovider};
  export default db;
  export  {storage};
  