import { Button, colors } from '@material-ui/core';

import React, { useRef, useState ,useContext,setCurrentUser,callback,RequestUserSignInResult} from "react"
import './Login.css';
import db, {firebaseApp} from './../../firebase';
import { auth, provider } from './../../firebase';
import { actionTypes } from '../../reducer';
import { useStateValue,AuthContext } from '../../StateProvider';


import Helmet from 'react-helmet';



import { Form,  Card, Alert } from "react-bootstrap"

import { useAuth } from './../../contexts/AuthContext'
import { Link,BrowserRouter,Redirect} from "react-router-dom"

import { BrowserRouter as Router, Route } from "react-router-dom";

//import Login from "./Signup";
import Signup from "./Signup";
import { AuthProvider } from './../../contexts/AuthContext'
import PrivateRoute from "./PrivateRoute";



import './../../App.css';
import SignUp from './Signup';




/*
function Login() {
  const [state, dispatch] = useStateValue(); 

  const signIn = () => {
    auth
      .signInWithPopup(provider) 
      .then((result) => {
        dispatch({
          type: actionTypes.SET_USER,
          user: result.user,
        });
        console.log(result);
      })
      .catch((error) => {
        alert(error.message);
      });
  };
*/

/*
 function Login() {
    const [state, dispatch] = useStateValue() 
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const emailRef = useRef()
    const passwordRef = useRef()
    const { login } = useAuth()
  const history = useHistory()
  
  const signIn = () => {
  auth
  .signInWithPopup(provider) 
    .then((result) => {
      dispatch({
        type: actionTypes.SET_USER,
        user: result.user,
      });
      console.log(result);
    })
    .catch((error) => {
      alert(error.message);
    });

  };

    

  


  async function handleSubmit(e) {
    e.preventDefault()

   
      setError("")
      setLoading(true)
    //  await login(emailRef.current.value, passwordRef.current.value)
      const result = await login(emailRef.current.value,passwordRef.current.value )
      .then((result) => {
        dispatch({
          type: actionTypes.SET_USER,
          user: result.user,
        });
       
        console.log(result);
      })
    
      .catch((error) => {
        alert(error.message);
      });

    setLoading(false)

  
  */


const Login = ({ history }) => {
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState()

 
 
  
 
  
 /* const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await firebaseApp
          .auth()
          .signInWithEmailAndPassword(email.value, password.value);
        //history.push("/");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );
*/
const emailRef = useRef()
const passwordRef = useRef()
//const { login } = useAuth() //useStateValue()

/*
const signIn = () => {
  auth
  .signInWithPopup(provider)
  .then((result) => {
    if (result.credential) {
      /** @type {firebase.auth.OAuthCredential} /
      const user = { username, password };

      var credential = result.credential;

      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = credential.accessToken;

      setUser(result.data)
      // store the user in localStorage
      localStorage.setItem('user', result.data) 
      // ...
    }
    // The signed-in user info.
    const [user, setCurrentUser] = result.user;
    //var user = result.user;
  }).catch((error) => {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    // The email of the user's account used.
    var email = error.email;
    // The firebase.auth.AuthCredential type that was used.
    var credential = error.credential;
    // ...
  });
}
*/
const [{state }, dispatch] = useStateValue(); 

  //const [{state }, dispatch] = useStateValue(); 
  const signIn = () => {
    auth
    .signInWithPopup(provider) 
      .then((result) => {
       /* dispatch({
          type: actionTypes.SET_USER,
          user: result.user,
         
        });*/
        
        console.log(result);
        localStorage.setItem('user', result.data) 
     //   result.user.displayName
     //   result.user.photoURL 
     localStorage.setItem('email', result.user.email) 
        localStorage.setItem('displayName',result.user.displayName)
        localStorage.setItem('photoURL',result.user.photoURL)
       
        localStorage.setItem('user_UID',result.user.uid)
        
       
        return db.collection('users').doc(result.user.uid).set({followers:0,following:0,USER_ID:result.user.uid,displayName:result.user.displayName,photoURL:result.user.photoURL})
      }).then(()=>
      {
       
        window.location.reload();
      });
    /*  .catch((error) => {
        alert(error.message);
      });*/

      localStorage.setItem('clickselect',1)
    }

    
 // const {  logout } = useAuth()
 // const { currentUser } = useContext(AuthContext);
/*
  if (currentUser) {
    return <Redirect to="/" />;
  }

 */

  /*
  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      await auth.signInWithEmailAndPassword("thetiptopp1@gmail.com","kkkk1111!")
      history.push("/")
    } catch {
      setError("Failed to log in")
    }

    setLoading(false)
  }

*/
/*
//working

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
    //  await login(emailRef.current.value, passwordRef.current.value)
    // const result = await login(emailRef.current.value,passwordRef.current.value )
    auth
       .signInWithEmailAndPassword(emailRef.current.value,passwordRef.current.value)     
     .then((UserCredential) => {

      
       
        localStorage.setItem('user', UserCredential.user) 
     //   result.user.displayName
     //   result.user.photoURL 
        localStorage.setItem('displayName',UserCredential.additionalUserInfo.displayName)
        localStorage.setItem('photoURL',UserCredential.additionalUserInfo.photoURL)
        console.log(UserCredential);
        window.location.reload();
      })
    
    

    setLoading(false)
    }
    catch {
      setError("Failed to log in")
    }
  };
  
  */

  function refreshPage(){ 
    window.location.reload(); 
}

  async function handleSubmit(e) {
    e.preventDefault()

   
      setError("")
      setLoading(true)
    //  await login(emailRef.current.value, passwordRef.current.value)
      const result = await auth.signInWithEmailAndPassword(emailRef.current.value,passwordRef.current.value )
      .then((result) => {
        dispatch({
          type: actionTypes.SET_USER,
          user: result.user,
        });
       
        console.log(result);
        localStorage.setItem('user', result.data) 
        //   result.user.displayName
        //   result.user.photoURL 
           localStorage.setItem('email', result.user.email) 
           localStorage.setItem('displayName',result.user.displayName)
           localStorage.setItem('photoURL',result.user.photoURL)
      })
    
      .catch((error) => {
        alert(error.message);
      });

    setLoading(false)

    }
  /*const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/" />;
  }
  */
  return (
    <>
     

 
        <div class="left">
       
        
   
        <span 
    id="textSpan" 
    style={  { color:`#2e81f4`, fontSize: '70px', marginRight: '0.5rem', fontWeight: 'bold'} }
>
    { "theideasplanet" }
</span>
       
        <Helmet>
<script async src="https://cse.google.com/cse.js?cx=d088bb816c07b437b"></script>
</Helmet> 
      
<div class="gcse-search"></div> 
  
       
        </div>
        <div class="right">
          
        <div className="login">
        <a class="tt space" href="https://tip.theideasplanet.com/">
        <span 
    id="textSpan" 
    style={  {color:`#2e81f4`, fontSize: '15px', fontWeight: 'bold'} }
>
    { "Explore" }
</span> </a>

     
<span>{'    '} </span>
  
 



         
    <Button type="submit" onClick={signIn}>
        Get In
      </Button>



    
     
   

    

  

    </div>
    <div className="footer">
    <span 
    id="textSpan" 
    style={  { color:`#2e81f4`, fontSize: '10px', marginRight: '0.5rem', fontWeight: 'bold'} }
>
    { "The Idea's Planet © Copyright 2021, All rights reserved by theideasplanet.com" }
</span>
</div>
        </div>       
    
  
    </>
  );
}




export default Login;
//export default Login;
