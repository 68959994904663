import React, { useEffect, useState } from 'react';

import './Follow.css';
import Followers from './Followers';

import db from '../../firebase'

var img =
  'https://tip.theideasplanet.com/images/tip.png';

function Follow() {

    const [followers, setfollowers] = useState([]);
/*
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    db.collection('posts').orderBy('timestamp', 'desc').limit(5).onSnapshot(snapshot => {
      setPosts(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data()})))
    })
  }, [])
  */

  const user_UID=localStorage.getItem('user_UID');
  var  user_UID_To_Follow;//"u0mDv8RWwIcM98Ouv7viIh37jIz1";
  
  if(localStorage.getItem('visiteUser')!=null)
  {
    user_UID_To_Follow=localStorage.getItem('visiteUser');
  }
  else
  {
    user_UID_To_Follow=localStorage.getItem('user_UID');
  }
  

useEffect(() => {
  
  db.collection('users').doc(user_UID_To_Follow).collection('followBy').onSnapshot(snapshot => {
    setfollowers(snapshot.docs.map(docX => ({ idX: docX.id, dataX: docX.data()})))
  })
}, [])

  return (
    <div className="Follow">

    

     {followers.map(post => (
//img=post.data.image

(true) ? (
        
  <Followers
  profilePic={img}
 profileSrc={post.dataX.profilepic}
 username={post.dataX.username}

 
   
  />
) :(

        <Followers
        profilePic={img}
        profilePic={post.dataX.profilepic}
        username={post.dataX.username}
      
     />
)
     ))}
    </div>
  );
}

export default Follow;
     