import React, { useAuth ,navigate} from "react";
import "./Header.css";
import SearchIcon from '@material-ui/icons/Search';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import WbIncandescentTwoToneIcon from '@material-ui/icons/WbIncandescentTwoTone';
import HomeTwoToneIcon from '@material-ui/icons/HomeTwoTone';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import FlagIcon from '@material-ui/icons/Flag';
import SubscriptionsOutlinedIcon from '@material-ui/icons/SubscriptionsOutlined';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import { Avatar, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ForumIcon from '@material-ui/icons/Forum';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStateValue } from '../../StateProvider';
import Followers from './Followers';
import Follow from './Follow';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';

import { Card, Button, Alert } from "react-bootstrap"

import { Link, useHistory } from "react-router-dom"

import { auth, provider } from './../../firebase';
import db from './../../firebase';
import  { useEffect, useState }from 'react';

//const history = useHistory();

function HeaderUserVisite()
{
 // localStorage.setItem('clickselect',1)
  //const {  logout } = useAuth()
//kk for followes code

const [posts, setPosts] = useState([]);
const [show, setShow] = useState('like2');
//const [followers, setfollowers] = useState(0);
const [show2, setShow2] = useState('textforlike');
const [flag, setFlag] = React.useState(true);
const [thumb, setThumbUp] = useState(false);

var keyy,username,image,userId;

const [{user} , dispatch] = useStateValue();
var displayName;// =  localStorage.getItem("displayName");
var photoURL;// = localStorage.getItem("photoURL");
const uID= localStorage.getItem('email');
 

const user_UID=localStorage.getItem('user_UID');
var  user_UID_To_Follow;//"u0mDv8RWwIcM98Ouv7viIh37jIz1";

if(localStorage.getItem('visiteUser')!=null)
{
  user_UID_To_Follow=localStorage.getItem('visiteUser');
}
else
{
  user_UID_To_Follow=localStorage.getItem('user_UID');
}
const routeRef = db.collection("users");



/*

const [followers, setfollowers] = useState([]);

useEffect(() => {
  
  db.collection('users').doc(user_UID_To_Follow).collection('followBy').onSnapshot(snapshot => {
    setPosts(snapshot.docs.map(docX => ({ idX: docX.id, dataX: docX.data()})))
  })
}, [])

*/

db.collection('users')
.doc(user_UID_To_Follow)
.get()
.then(docc => {
    const data1 = docc.data()
    localStorage.setItem('displayName1',data1.displayName)
    localStorage.setItem('photoURL1',data1.photoURL)
  
})


/*
const nameFilter = followers ? routeRef.where("idU", "==", uID) : routeRef;
//const expertiseFilter = ExpertiseInput ? nameFilter.where("Expertise", "==", ExpertiseInput) : nameFilter;
//const locationFilter = LocationInput ? expertiseFilter.where("Location", "==", LocationInput) : expertiseFilter;
nameFilter.get().then(snapshot => {
   // The snapshot returned by `where().get()` does not have a `data()` reference since it returns multiple documents, it has `docs` property which is an array of all the documents matched
   snapshot.docs.forEach(doc => {
     const docData = { ...doc.data(), id: doc.id };
  
     setfollowers(docData.followers);    
     console.log(docData);
})
}
)
*/



  const ThumbUpButton = {
    backgroundColor: 'rgb(115, 250, 179)',
    border: 'none',
    borderRadius: '5px',
  }
  
  const ThumbStyle = {
    backgroundColor: 'red',
    border: 'none',
    padding: '5px',
    borderRadius: '5px',
    margin: '1rem'
  }

  
var noF;

  useEffect(() => {
    db.collection('users')
        .doc(user_UID)      
        .get()
        .then(doc2 => {
            if (doc2.data()) {
                if (show == 'like2') {
                    setShow('like2 blue');
                    setShow2('textforlike bluetextforlike')
                    setFlag(!flag);
                } else {
                    setShow('like2');
                    setShow2('textforlike')
                    setFlag(flag);
                }
            }
        })
});



const likeHandle = (event) => {
    event.preventDefault();
    if (show == 'like2') {
        setShow('like2 blue');
        setShow2('textforlike bluetextforlike')
        setFlag(!flag);
    } else {
        setShow('like2');
        setShow2('textforlike')
        setFlag(flag);
    }



    /*
    db.collection('users')
        .doc(user_UID)
        .get()
        .then(docc => {
            const data = docc.data()
            console.log(show)
            if (show == 'like2') {
                db.collection("users")
                    .doc(user_UID)                  
                    .get()
                    .then(doc2 => {
                        if (doc2.data()) {
                          db.collection('users').doc(user_UID).update({
                            followers: data.followers + 1
                          });
                        } else {
                            db.collection("users").doc(user_UID).set({
                                followers: 1
                            });
                           
                        }
                    })

            } else {
               
              if(data.followers>=1)
              {
                //  db.collection('users').doc(user_UID).delete().then(function () {
                    db.collection('users').doc(user_UID).update({                      
                      followers: data.followers - 1                   
                      
                    });
               // })
              }
              else
              {
               // db.collection('users').doc(user_UID).delete().then(function () {
                  db.collection('users').doc(user_UID).update({                          
                    followers:0                     
                    
                  });
             // })
              }
              
            }
        })
        */

        db.collection('users')
        .doc(user_UID_To_Follow)
        .get()
        .then(docc => {
            const data = docc.data()
            console.log(show)
            if (show == 'like2') {
                db.collection('users')
                    .doc(user_UID)
                    .collection('followBy')
                    .doc(user_UID)
                    .get()
                    .then(doc2 => {
                        if (doc2.data()) {
                          noF=data.nofollowers  
                          console.log(doc2.data())

                        } else {
                            db.collection('users').doc(user_UID_To_Follow).collection('followBy').doc(user_UID).set({
                                followers: 1
                            });
                            db.collection('users').doc(user_UID_To_Follow).update({
                                nofollowers: data.nofollowers + 1
                            });

                            noF=data.nofollowers 
                        }
                    })

            } else {
               
              if(data.nofollowers>=1)
              {
                  db.collection('users').doc(user_UID_To_Follow).collection('followBy').doc(user_UID).delete().then(function () {
                    db.collection('users').doc(user_UID_To_Follow).update({                      
                      nofollowers: data.nofollowers - 1                      
                      
                    });
                })

                noF=data.nofollowers 
              }
              else
              {
                db.collection('users').doc(user_UID_To_Follow).collection('followBy').doc(user_UID).delete().then(function () {
                  db.collection('users').doc(user_UID_To_Follow).update({                      
                    nofollowers:0                     
                    
                  });
              })
              noF=data.nofollowers 
              }
              
            }
        })

}

// kk for followers code

  const    handleSubmit = async () => {
      {
       localStorage.setItem('clickselect',1)
       localStorage.setItem('clickselect1',0)
      window.location.reload();
      return
    }
  }

  const    handleSubmitMyFeed = async () => {
    {
    localStorage.setItem('clickselect',2)
    localStorage.setItem('clickselect1',0)
    window.location.reload();
    return
  }
}

const    handleSubmitIdea = async () => {
  {
    localStorage.setItem('clickselect',3)
    localStorage.setItem('clickselect1',0)
  window.location.reload();
  return
}
}


 
  
  const handleLogout = async () => {
      try {
        return this.ngFireAuth.auth.signOut().then(() => {
       
       //   this.router.navigate(['login']);
        })
       
      } catch (e) {
          console.log(e);
      }
      localStorage.removeItem('user');
      localStorage.removeItem('displayName');
      localStorage.removeItem('photoURL');
      localStorage.clear();
     // localStorage.signOut();
     // <Link to="/Login"></Link>
     window.location.reload();
  }
    




   
    return (
    <div className="header">
        <div className="header_left">
        <img src="https://theideasplanet.com/images/logo.png" 
        alt="theideasplanet logo" />

        </div>
        <div className="header__input">
            <SearchIcon  />
            <input placeholder="Search theideasplanet"  type="text"/>
        </div>

        <div className="header__center">
        
        <div className="header__option">
            <HomeTwoToneIcon fontsize="large"/>
            
        </div>
        <Button variant="link" onClick={handleSubmit}>
         Home
        </Button>
        <div className="header__option">
            <DynamicFeedIcon fontsize="large"  />
            <Button variant="link" onClick={handleSubmitMyFeed}>
           My News 
        </Button>
            
        </div>
        
        <div className="header__option">
            <WbIncandescentTwoToneIcon fontsize="large"  />
           
        </div>
        <Button variant="link" onClick={handleSubmitIdea}>
          My ideassssssssss
        </Button>
       
        </div>

        <div className="header_middle">

     
        </div>

        <div className="header__right">

        <div className="header__info">
        <Avatar src={ localStorage.getItem('photoURL1')}/>
           <h4>{ localStorage.getItem('displayName1')}</h4>
        </div>
        <IconButton>
          <AddIcon />
        </IconButton>
        <IconButton>
        <div className="post__option" onClick={likeHandle}>
         
         <button
 style={{
   border: 'none',
   backgroundColor: 'transparent'
 }}
 onClick={() => setThumbUp(thumb => !thumb)}
>
       < FollowTheSignsIcon  color={thumb ?  "primary" :"secondary"} >Follows</FollowTheSignsIcon>       
       <h6>{noF}</h6>
       
       </button>
        </div>
        </IconButton>
        <IconButton>
          <NotificationsActiveIcon />
        </IconButton>
        <IconButton>
          <ExpandMoreIcon />
        </IconButton>
        
        <Button variant="link" onClick={handleLogout}>
          Log Out
        </Button>
       
       
      
     

        
      </div>
      <Follow/>
      
        </div>


  );
}

export default HeaderUserVisite;